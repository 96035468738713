<template>
    <div id="how-order" class="about container">
        <div class="how-order-title mt-5 bg-white">
            <h3>Kebijakan Privasi</h3>
            <hr class="price-line" />
        </div>

        <div class="how-to-steps">
            <ol>
                <li>
                    <h4>Definisi</h4>
                    <ul class="privacy-item">
                        <li>
                            Kepatuhan Anda: Sebelum menggunakan, mengakses atau memanfaatkan situs ini, Anda sudah membaca
                            dengan baik setiap dan seluruh Syarat dan Ketentuan ini yang antara lain berisi mengenai Hak
                            Cipta, Kewajiban Penulis, Bagi hasil Penulis dan ketentuan menerbitkan buku yang berkaitan
                            dengan penulis guepedia.com. Dan dengan melanjutkan penggunaan atau pemanfaatan fasilitas yang
                            diberikan oleh guepedia.com maka Anda telah menyatakan persetujuan Anda terhadap setiap dan
                            seluruh ketentuan dalam Syarat dan Ketentuan ini.
                        </li>
                        <li>
                            Keanggotaan di Guepedia.com adalah terbuka bagi semua kalangan, baik penulis buku, pembeli buku
                            maupun penikmat buku.
                        </li>
                        <li>Keanggotaan di Guepedia.com adalah GRATIS dengan mengisi form data keanggotaan secara lengkap
                            dan benar.</li>
                    </ul>
                </li>

                <li>
                    <h4>Hak Cipta</h4>
                    <ul class="privacy-item">
                        <li>
                            Penulis menyatakan dengan sebenarnya telah menyerahkan sebuah naskah yang telah diketik kepada
                            Guepedia.com.
                        </li>
                        <li>
                            Penulis menyerahkan hak terbit kepada Guepedia.com selama kurun waktu 5 (lima) tahun sejak
                            perjanjian disetujui. Selama itu penulis tidak boleh menerbitkan di penerbit lain dan menarik
                            naskah dalam hal apapun.
                        </li>
                        <li>
                            Selama Perjanjian ini berlaku, Guepedia.com dan Penulis bersama – sama melindungi Hak Cipta
                            intelektual Penulis yang ada pada kedua belah pihak.
                        </li>
                    </ul>
                </li>

                <li>
                    <h4>Kewajiban Penulis</h4>
                    <ul class="privacy-item">
                        <li>
                            Penulis menjamin bahwa ia tidak menyerahkan karya tersebut kepada pihak lain untuk diterbitkan
                            atau diterjemahkan.
                        </li>
                        <li>
                            Penulis menjamin naskah yang akan diterbitkan oleh Guepedia.com belum pernah diterbitkan oleh
                            penebit lain. Jika penulis sudah pernah menerbitkan dipenerbit lain dan ingin diterbitkan hanya
                            di guepedia.com maka harus ada surat keterangan pencabutan naskah dari penerbit sebelumnya.
                        </li>
                        <li>
                            Penulis menjamin bahwa karya tersebut tidak mengandung sesuatu yang melanggar hak cipta orang
                            lain dan melanggar hukum.
                        </li>
                        <li>
                            Penulis menjamin bahwa karya tersebut tidak mengandung sesuatu yang dapat dianggap sebagai
                            penghinaan atau fitnahan terhadap pihak lain.
                        </li>
                        <li>
                            Penulis menjamin bahwa karya tersebut tidak mengandung sesuatu yang berbau pornografi, SARA,
                            LGBT dan mengandung kontroversi.
                        </li>
                        <li>
                            Penulis membebaskan Guepedia.com dari segala tuntutan pihak ketiga berdasarkan hal – hal yang ia
                            jamin dalam hal ketiga ayat tersebut di atas, jika kesalahan terbukti semata – mata ada pada
                            Penulis, terutama yang mengenai isi buku.
                        </li>
                        <li>
                            Penulis tidak diperkenankan membuat karangan lain yang judul dan isinya sama atau judul yang
                            diubah tapi isi sama atau judul sama tapi isi di ubah atau dalam bentuk apa pun yang merugikan
                            Guepedia.com dalam penjualan karya tersebut.
                        </li>
                        <li>
                            Penulis tidak di perkenankan menyuruh orang lain menerbitkan atau membantu usaha orang lain
                            untuk menerbitkan karya yang judul & isinya sama, atau judul yang diubah tapi isi sama atau
                            judul sama tapi isi diubah atau dalam bentuk apa pun.
                        </li>
                        <li>
                            Penulis tetap mempunyai hak untuk melakukan revisi, perbaikan atau penyempurnaan apabila pada
                            naskah tersebut ditemukan kesalahan atau ketidaksempurnaan atau apabila diminta oleh
                            Guepedia.com.
                        </li>
                        <li>
                            Apabila diperlukan, penulis wajib memberikan diskripsi tentang tata wajah, ringkasan cerita,
                            ilustrasi naskah, daftar gambar, foto-foto, daftar istilah, atau hal-hal lain yang berhubungan
                            dengan kelengkapan naskah.
                        </li>
                    </ul>
                </li>

                <li>
                    <h4>Kewajiban Guepedia.com</h4>
                    <ul class="privacy-item">
                        <li>Guepedia.com menyanggupi untuk segera menerbitkan naskah penulis dalam bentuk buku fisik maupun
                            digital.</li>
                        <li>Guepedia.com akan mempromosikan serta memasarkan buku tersebut seluas mungkin.</li>
                        <li>Guepedia bisa mendaftarkan ISBN atau tanpa ISBN untuk diterbitkan.</li>
                        <li>Pendaftaran ISBN oleh Guepedia bisa menggunakan akun ISBN Guepedia atau line penerbit di bawah
                            group Guepedia, seperti akun ISBN : Spasi Media, Kreatif Publishing, E Media, Bukupedia.</li>
                        <li>Guepedia.com tidak bertanggung jawab atas isi naskah yang diterbitkan jika dikemudian hari
                            ditemukan hal-hal yang melanggar hukum, norma,dan asusila.</li>
                    </ul>
                </li>

                <li>
                    <h4>Hak Guepedia.com</h4>
                    <ul class="privacy-item">
                        <li>Guepedia.com berhak memperbaiki naskah, menetapkan tata wajah, tata letak, bentuk buku, jumlah
                            halaman, ilustrasi, penentuan harga, proses cetakan, dan cara penjualannya.</li>
                        <li>Guepedia.com juga diberikan hak untuk menyebarluaskan karya penulis tersebut dalam bentuk lain,
                            seperti Film, Sinetron, Video, dan lain-lain, baik sebagian ataupun keseluruhan naskah.</li>
                        <li>Guepedia.com berhak untuk menarik dan tidak menerbitkan naskah yang sedang berjalan dengan atau
                            tanpa alasan apapun.</li>
                        <li>Guepedia berhak untuk tidak memberikan file atau bentuk apapun dari naskah yang sudah
                            diterbitkan kepada penulis maupun pihak lainnya.</li>
                    </ul>
                </li>

                <li>
                    <h4>Bagi Hasil</h4>
                    <ul class="privacy-item">
                        <li>
                            Guepedia.com akan membayar bagi hasil kepada Penulis sebesar 15% dari harga jual buku sebelum
                            pajak tambahan. Dan bagi hasil akan dikurangkan pajak sesuai dengan UUD perpajakan yang berlaku
                            di Indonesia.
                        </li>
                        <li>
                            Bagi hasil penulis tidak terikat dalam jumlah 15% (bisa lebih dari 15%) tergantung promo yang
                            akan ditawarkan oleh Guepedia.com.
                        </li>
                        <li>
                            Penulis bisa menarik bagi hasil setiap bulannya.
                        </li>
                    </ul>
                </li>

                <li>
                    <h4>Penerjemahan Naskah</h4>
                    <ul class="privacy-item">
                        <li>
                            Jika naskah atau buku di terbitkan dalam bahasa lain, maka Guepedia.com berhak menerjemahkannya.
                            Segala biaya penerjemahan akan di bicarakan kepada penulis Apabila penulis meninggal dunia, maka
                            segala hak dan kewajibannya yang berhubungan dengan surat perjanjian ini beralih kepada ahli
                            warisnya yang sah menurut hukum.
                        </li>
                        <li>
                            Apabila ahli waris penulis lebih dari seorang, maka mereka harus menunjuk seorang ahli waris
                            yang diberi surat kuasa penuh untuk berhubungan dengan guepedia.com.
                        </li>
                        <li>
                            Apabila penunjukkan tersebut tidak dilakukan dan diberitahukan kepada Guepedia.com, maka
                            Guepedia.com berhak melakukan segala sesuatu mengenai hak-hak dan kewajiban-kewajiban mereka
                            dengan layak dan sebaik-baiknya.
                        </li>
                    </ul>
                </li>

                <li>
                    <h4>Penyelesaian Perselisihan</h4>
                    <ul class="privacy-item">
                        <li>
                            Apabila penulis sudah menyetujui perjanjian ini sebelum kurun waktu lima tahun melanggar
                            perjanjian maka bagi hasil penulis akan dibekukan oleh Guepedia.com.
                        </li>
                    </ul>
                </li>

                <li>
                    <h4>UU ITE NO.11 TAHUN 2008 BAB III MENGENAI SURAT ELEKTRONIK</h4>
                    <ul class="privacy-item">
                        <li>
                            <strong>PASAL 5</strong><br />
                            Informasi Elektronik dan/atau Dokumen Elektronik dan/atau hasil cetaknya merupakan alat bukti
                            hukum yang sah.
                        </li>
                        <li>
                            <strong>PASAL 5 AYAT 4</strong><br />
                            Surat yang menurut undang-undang harus dibuat tertulis meliputi tetapi tidak terbatas pada surat
                            berharga, surat yang berharga, dan surat yang digunakan dalam proses penegakan hukum acara
                            perdata, pidana, dan administrasi negara.
                        </li>
                        <li>
                            <strong>PASAL 6</strong><br />
                            Dalam hal terdapat ketentuan lain selain yang diatur dalam Pasal 5 ayat (4) yang mensyaratkan
                            bahwa suatu informasi harus berbentuk tertulis atau asli, Informasi Elektronik dan/atau Dokumen
                            Elektronik dianggap sah sepanjang informasi yang tercantum di dalamnya dapat diakses,
                            ditampilkan, dijamin keutuhannya, dan dapat dipertanggungjawabkan sehingga menerangkan suatu
                            keadaan.
                        </li>
                        <li>
                            <strong>PASAL 7</strong><br />
                        Setiap Orang yang menyatakan hak, memperkuat hak yang telah ada, atau menolak hak Orang lain
                        berdasarkan adanya Informasi Elektronik dan/atau Dokumen Elektronik harus memastikan bahwa
                        Informasi Elektronik dan/atau Dokumen Elektronik yang ada padanya berasal dari Sistem Elektronik
                        yang memenuhi syarat berdasarkan Peraturan Perundang-undangan.
                    </li>
                </ul>
            </li>

        </ol>
    </div>
</div></template>

<script>
export default {
    data() {
        return {
            mainProps: {
                center: true,
                fluidGrow: true,
                blank: true,
                blankColor: '#bbb',
            }
        }
    },
    metaInfo() {
        return {
            title: 'Kebijakan Privasi',
            meta: [
                { name: 'description', content: 'Penerbit buku online gratis, anti ribet, dan cepat, puluhan ribu naskah sudah terbit disini, sekarang giliran naskahmu.' },
                { property: 'og:type', content: "website" },
                { property: 'og:url', content: "https://www.guepedia.com/" },
                { property: 'og:title', content: 'Penerbit buku online gratis Indonesia  | Guepedia.com' },
                { property: 'og:description', content: 'Penerbit buku online gratis, anti ribet, dan cepat, puluhan ribu naskah sudah terbit disini, sekarang giliran naskahmu.' },
            ]
        }
    },
    methods: {
        getImageUrl(imageId) {
            return `https://www.guepedia.com/uploads/how-to-order/${imageId}`
        }
    }
}
</script>

<style scoped>.privacy-item li {
    text-align: justify;
    font-size: 0.9em;
    list-style: disc;
}</style>
