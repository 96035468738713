<template>
  <div class="book-action">
    <div class="side-detail card shadow p-3 mb-5">
      <div class="side-detail-item mb-3">
        <div class="side-detail-title mb-3">
          <div class="cart-title">Periksa Ongkos Kirim</div>
          <hr class="price-line" />
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12 col-12 col-sm-12 mb-3">
            <v-select label="item_data"
            placeholder= "Pilih Kota"
            @input="getDistrict"
            :options="kota" v-model="selected"
            :dropdown-should-open="dropdownShouldOpen"></v-select>
          </div>
          <div class="col-12 col-sm-12 mb-3">
            <v-select label="subdistrict_name"
            placeholder= "Pilih Kecamatan"
            :reduce="district => district.subdistrict_id"
            :options="districts" v-model="selectedDistrict" :disabled="!selected"></v-select>
          </div>
          <div class="col-md-6 col-lg-6 col-12 col-sm-12 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Nama Provinsi"
              readonly
              v-model="province"
            />
          </div>
          <div class="col-md-3 col-lg-3 col-12 col-sm-12 mb-3">
            <input type="text" v-model="postal" class="form-control" placeholder="Kode Pos" readonly/>
          </div>
          <div class="col-md-3 col-lg-3 col-12 col-sm-12 mb-3">
            <input
              type="number"
              class="form-control"
              placeholder="Jumlah Buku"
              v-model="qty"
            />
          </div>
          <!-- <div class="col-md-8 col-lg-8 col-12 col-sm-12 mb-3">
            <select class="form-control" v-model="service">
              <option value="jne">JNE</option>
              <option value="jnt">JNT</option>
              <option value="sicepat">Sicepat</option>
              <option value="anteraja">Anter Aja</option>
              <option value="indah">Indah Cargo</option>
            </select>
          </div> -->
        </div>
        <div class="action-btn mt-3 text-center">
          <button type="button" class="form-control btn main-btn" @click="getOngkir">
            Periksa
          </button>
        </div>
      </div>
    </div>

    <div class="data-ongkir card shadow p-3" v-if="data_ongkir">

      <div class="service-item" v-for="service in data_ongkir" :key="service">
        <h6>{{service.name}}</h6>
        <hr class="price-line">

        <div class="ongkir-item" v-for="item in service.costs" :key="item.service">
          <div class="ongkir-title">{{item.description}} | {{ item.cost[0].etd}} Hari |
          <span class="ongkir-value">{{item.cost[0].value | currency}}</span></div>
          <hr/>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "Cart",
  props:['book_weight'],
  components: {},
  data() {
    return {
      kota: [],
      districts: [],
      selectedDistrict: null,
      selected: null,
      province: null,
      postal: null,
      qty: 1,
      service: '',

      data_ongkir: []
    };
  },
  methods:{
    dropdownShouldOpen(VueSelect){
      if (this.selected !== null) {
        return VueSelect.open
      }

      return VueSelect.search.length !== 0 && VueSelect.open
    },

    getOngkir() {
      var subdistrict = this.selectedDistrict;
      var totalWeight = this.qty * this.book_weight;

      var formData = new FormData();
      formData.append("destination", subdistrict);
      formData.append("origin", "78");
      formData.append("weight", totalWeight);
      formData.append("originType", "city");
      formData.append("destinationType", "subdistrict");

      axios
        .post("getOngkir", formData, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.data_ongkir = response.data.data.ongkir;
          console.log(this.data_ongkir);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOngkir = false));
    },

    getCity(){
      this.loading = true;
      axios
        .get("dataKota" , {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.kota = response.data.data.data;
          this.kota.map(function (x){
            return x.item_data = x.type + ' ' + x.city_name;
          });

          console.log(this.kota);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    getDistrict(){

      this.province = this.selected.province
      this.postal = this.selected.postal_code

      axios
        .get("subdistricts/" + this.selected.city_id, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.districts = response.data.data;

          console.log(this.districts);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    }
  },
  mounted(){
    this.getCity();
  },
};
</script>
<style>
.ongkir-title{
  font-size: 0.9em;
}
.ongkir-title, .ongkir-value{
  font-weight: bolder;
  color: yellowgreen;
}
.ongkir-value{
  color: black !important;
}
</style>