<template>
  <div id="admin-dashboard" class="mt-3">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">Data Reseller {{status}}</h3>
      </div>
    </div>
    <!-- End Page Header -->
    <statistik/>
    <datas/>
  </div>
</template>

<script>
import Datas from "@/components/Admin/DataReseller/Data.vue";
import Statistik from "@/components/Admin/DataReseller/Statistics.vue";
export default {
  components: {
      Datas,
      Statistik
  },
  data(){
    return{
      status: this.$route.params.status,
    }
  },
  watch:{
    $route(to, from){
      this.status = to.params.status;
    }
  }
}
</script>
