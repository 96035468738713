<template>
  <div id="app">
    <NavigationBar class="d-none d-md-block"></NavigationBar>
    <mobile-navigation-bar class="d-block d-md-none" />

    <div class="content-area mb-3">
      <div class="container-fluid">
        <router-view />
      </div>
    </div>

    <div class="text-center">
      <Adsense
        data-ad-client="ca-pub-3076489163946110"
        data-ad-slot="6109811926">
      </Adsense>
    </div>

    <footer-bar />

    <a href="https://api.whatsapp.com/send?phone=6281287602508" data-bs-toggle="tooltip" data-bs-placement="left" title="Hubungi Admin" class="float" target="_blank">
      <i class="bi bi-whatsapp"></i>
    </a>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import MobileNavigationBar from "@/components/MobileNavigationBar";
import FooterBar from "../components/Footer.vue";
export default {
  components: {
    NavigationBar,
    MobileNavigationBar,
    FooterBar,
  },
  data() {
    return {};
  },
};
</script>
<style>
.content-area {
  margin-top: 4em;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .content-area {
    margin-top: 6.5em;
  }
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:10px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:40px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.float i{
	color: #FFF;
}
</style>
