<template>
  <div id="admin-login-layout">
    <router-view/>
  </div>
</template>

<script>
export default {
  components:{
    
  }
}
</script>

<style>
#admin-login-layout{
    background: linear-gradient(135deg, rgba(3,84,138,1) 0%, rgba(145,232,66,1) 100%);
}
</style>