<template>
  <div class="navigation-bar fixed-top">
    <div class="main-nav p-2">
      <div class="d-flex justify-content-evenly">
        <div class="category-btn">
          <div class="dropdown">
            <button type="button" class="btn btn-light btn-sm" @click="showCategory = !showCategory">
              Kategori
            </button>
          </div>
        </div>

        <div class="input-group input-group-sm search-form mx-3">
          <input type="text" class="form-control" placeholder="Cari Judul Atau Nama Penulis"
            aria-label="Cari Judul Atau Nama Penulis" aria-describedby="button-addon2" v-model="keyword" />
          <button class="btn btn-secondary" type="submit" id="button-addon2" @click="applyKeyword">
            <b-icon icon="search"></b-icon>
          </button>
        </div>

        <div class="category-btn">
          <div class="dropdown">
            <button type="button" class="btn btn-light btn-sm" @click="showMenu = !showMenu">
              <i class="bi bi-list" v-if="!showMenu"></i>
              <i class="bi bi-x" v-else></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="category-menu cat-mb px-3 py-3 bg-white" v-if="showCategory">
      <div class="row">
        <div class="col-12 col-md-3 py-1" v-for="item in categories" :key="item.id_kategori"
          @click="showCategory = !showCategory">
          <router-link :to="{
            name: 'OurProduct',
            query: { cat: item.id_kategori_naskah },
          }">{{ item.nama_kategori }}</router-link>
        </div>
      </div>
    </div>

    <div class="category-menu cat-mb px-3 py-3 bg-white" v-if="showMenu">
      <div class="text-center">
        <b-link to="/">
          <img src="https://www.guepedia.com/assets/img/logo-toko.png" />
        </b-link>
      </div>

      <hr />

      <div class="d-flex">
        <div class="action-btn ms-auto">
          <b-link to="/cart">
            <div class="btn-sm wrapper">
              <i class="bi bi-cart3"></i>
              <span class="badge notif-badge" v-if="totalQty">{{
              totalQty
              }}</span>
            </div>
          </b-link>
        </div>

        <div class="login-btn ms-3 me-auto">
          <a href="/login" v-if="!Auth"><button type="button" class="btn btn-sm">Login</button></a>

          <b-link to="/dashboard" v-else>
            <div class="btn-sm d-flex align-items-center">
              <b-avatar :text="name | initial" class="me-2" v-if="avatar == 'null'" size="sm"></b-avatar>
              <b-avatar v-else variant="info" :src="
                'https://www.guepedia.com/uploads/photo_profile/' + avatar
              " class="me-2" size="sm"></b-avatar>
              <span class="me-3">{{ name }}</span>
            </div>
          </b-link>
        </div>
      </div>

      <div class="menu-item row">
        <div class="col-12 py-1" @click="showMenu = !showMenu">
          <router-link :to="{ name: 'Index' }"><strong>Home</strong></router-link>
        </div>

        <div class="penulis-menu mt-2" v-if="Auth">
          <strong>Menu Penulis</strong>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Upload' }">Upload Naskah</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Project' }">Naskah Saya</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ReviewBuku' }">Review Buku</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Haki' }">Daftar Hak Cipta</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Sales' }">Laporan Penjualan</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Royalty' }">Bagi Hasil</router-link>
            </li>
          </ul>
        </div>

        <div class="penulis-menu mt-1" v-if="Auth">
          <strong>Data Transaksi</strong>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'Transaction' }">Transaksi Saya</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Account' }">Pengaturan Profil</router-link>
            </li>
          </ul>
        </div>

        <div class="penulis-menu mt-1" v-if="Auth">
          <strong>Pusat Bantuan</strong>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'Terbit' }">
                <span v-if="now <= date">Terbit Gratis</span>
                <span v-else>Cara Terbit</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'CaraOrder' }">Cara Beli Buku</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'JadiPenulis' }">Cara Jadi Penulis</router-link>
            </li>
            <li>
              <a href="https://reseller.guepedia.com">Cara Jadi Reseller</a>
            </li>
          </ul>
        </div>

        <div class="side-menu-item logout my-1 mt-3 text-center" v-if="Auth">
          <button class="btn btn-light logout-btn" @click="logout">
            <i class="bi bi-power"></i><span class="ms-3">LOGOUT</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      avatar: localStorage.getItem("avatar"),
      name: localStorage.getItem("name"),

      categories: [],
      showCategory: false,
      showMenu: false,
      keyword: "",

      date: "",
      now: new Date(),
    };
  },

  methods: {
    ...mapActions(["saveCart"]),
    applyKeyword() {
      var cat = this.$route.query.cat;
      var keyword = this.keyword;
      this.$router.push({
        name: "OurProduct",
        query: { cat: cat, keyword: keyword },
      });
    },
    getCategory() {
      this.loading = true;
      axios
        .get("getCategory", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.categories = response.data.data.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    logout(){
      const token = localStorage.getItem('access_token')
      const AuthStr = 'Bearer '+ token; 
      axios.post('logout', {api_token: AuthStr}, { headers: {
          "Access-Control-Allow-Headers" : "*",
          'Content-Type' : 'application/json',
          'Accept' : 'application/json',
          'Authorization' : AuthStr
          }
      }).then(response => {
          // console.log(response)
          localStorage.clear()
          this.$router.go()
        })
      .catch(error => console.log(error));
    },

    getData() {
      axios
        .get("getTerbitGratis", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.date = new Date(response.data.data.data.value);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters(["cart"]),
    totalQty() {
      return this.cart.reduce((a, b) => a + b.qty, 0);
    },
    Auth() {
      if (localStorage.getItem("access_token")) {
        return true;
      }
    },
  },
  mounted() {
    this.getCategory();
    this.getData();
  },
  watch: {
    cart: {
      handler(cart) {
        console.log(cart);
      },
      deep: true,
    },
    $route() {
      this.showMenu = false;
    },
  },
};
</script>

<style>
.cat-mb {
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
}

.notif-badge {
  font-size: 0.6em;
  background: red !important;
}

.wrapper {
  position: relative;
}

.wrapper i {
  font-size: 1.3em;
}

.wrapper span {
  position: absolute;
  top: -2px;
  right: -2px;
}

.category-menu a:hover {
  color: yellowgreen !important;
}

.menu-list {
  padding-left: 1rem;
}

.penulis-menu li {
  list-style: none;
  line-height: 2.2em;
}
</style>
