<template>
  <div class="bg-white shadow p-3">
    <div class="text-center fw-bold mb-3">Bergabung dengan kami! Sekarang</div>
    <form @submit.prevent="Register">
      <div class="row">
        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerName" class="form-label">Nama Lengkap</label>
            <input type="text" class="form-control" id="registerName" v-model="form.name" required />
          </div>
        </div>

        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerPena" class="form-label">Nama Pena</label>
            <input type="text" class="form-control" id="registerPena" v-model="form.pena" required />
          </div>
        </div>

        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerPhone" class="form-label">Nomor Kontak</label>
            <input type="text" class="form-control" id="registerPhone" v-model="form.kontak" required />
          </div>
        </div>

        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerEmail" class="form-label">Email</label>
            <input type="email" class="form-control" id="registerEmail" v-model="form.email" required />
          </div>
        </div>

        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerPassword" class="form-label">Password</label>
            <input type="password" class="form-control" id="registerPassword" v-model="form.password" required />
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerConfirmPassword" class="form-label">Konfirmasi Password</label>
            <input type="password" class="form-control" id="registerConfirmPassword" v-model="form.password_confirmation"
              required />
          </div>
        </div>
      </div>

      <div class="fw-bold mb-3">Data Alamat Anda</div>
      <div class="row">
        <!-- <div class="col-md-6 col-lg-6">
          <div class="mb-3">
            <label for="registerProvince" class="form-label">Provinsi</label>
            <input type="text" class="form-control" id="registerProvince" v-model="form.province"/>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="mb-3">
            <label for="registerCity" class="form-label"
              >Kota / Kabupaten</label
            >
            <input type="text" class="form-control" id="registerCity" v-model="form.city"/>
          </div>
        </div> -->
        <div class="col-md-12 col-lg-12">
          <div class="mb-3">
            <label for="registerAddress" class="form-label">Alamat Lengkap</label>
            <textarea class="form-control" id="registerAddress" v-model="form.address" required />
          </div>
        </div>
      </div>

      <div class="fw-bold mb-3">Akun Sosial Media Anda</div>
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="input-group mb-3">
            <span class="input-group-text" id="facebook"><i class="bi bi-facebook"></i></span>
            <input type="text" class="form-control" aria-describedby="facebook" v-model="form.facebook" />
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="input-group mb-3">
            <span class="input-group-text" id="instagram"><i class="bi bi-instagram"></i></span>
            <input type="text" class="form-control" aria-describedby="instagram" v-model="form.instagram" />
          </div>
        </div>
      </div>

      <div class="login-btn text-end mb-3">
        <button type="submit" class="btn btn-sm">Daftar</button>
      </div>
    </form>

    <div class="side-menu-item text-center">
      <a href="/login">
        <span class="ms-3">Sudah punya akun? Masuk Sekarang</span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        name: "",
        pena: "",
        kontak: "",
        email: "",
        password: "",
        password_confirmation: "",
        address: "",
        facebook: "",
        instagram: "",
      },
    };
  },

  metaInfo() {
    return {
      title: 'Daftar',
      meta: [
        { name: 'description', content: 'Penerbit buku online gratis, anti ribet, dan cepat, puluhan ribu naskah sudah terbit disini, sekarang giliran naskahmu.' },
        { property: 'og:type', content: "website" },
        { property: 'og:url', content: "https://www.guepedia.com/" },
        { property: 'og:title', content: 'Penerbit buku online gratis Indonesia  | Guepedia.com' },
        { property: 'og:description', content: 'Penerbit buku online gratis, anti ribet, dan cepat, puluhan ribu naskah sudah terbit disini, sekarang giliran naskahmu.' },
      ]
    }
  },

  methods: {
    Register() {
      axios
        .post("register", {
          nama_lengkap: this.form.name,
          nama_pena: this.form.pena,
          nomor_kontak: this.form.kontak,
          alamat_lengkap: this.form.address,
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          facebook: this.form.facebook,
          instagram: this.form.instagram,
        })
        .then((response) => {
          console.log(response);

          this.$router.push('/login');

          this.openNotification(
            6000,
            "top-right",
            "success",
            "Registration Success",
            response.data.message
          );

          this.loading = false;
        })
        .catch((error) => {
          this.openNotification(
            6000,
            "top-right",
            "danger",
            "Autentikasi Gagal",
            error.response.data.message
          );
          reject(error);
          this.loading = false;
        });
    },
  },
};
</script>
