<template>
  <div class="term-area p-3 bg-white shadow">
    <div class="term-title">
      <h4 class="fw-bold">Tata Cara Menjadi Reseller</h4>
      <hr class="price-line" />
      <div class="term-list" style="font-size:0.9em">
        <p>Harap dibaca dengan seksama ketentuan reseller Guepedia</p>
        <ol>
          <li>Ada niat yang kuat untuk menjadi reseller, bukan hanya ikut-ikutan dan coba-coba.</li>
          <li>Pelajari cara dan tingkatkan pengetahuan dalam menjual.</li>
          <li>Buku yang terbit saat ini berjumlah 14.000 ribu judul buku dan akan terus bertambah.</li>
          <li>Deposit awal sebesar Rp.250.000, ( duaratus lima puluh ribu rupiah ) yang kemudian akan menjadi saldo reseller anda yang dapat dibelanjakan di website <strong>guepedia.com</strong>.
          </li>
          <li>Jika akun anda terdaftar sebagai reseller, anda akan otomatis mendapatkan potongan harga khusus reseller yang dapat digunakan selamanya.</li>
          <li>Potongan harga reseller tidak dapat dugabungkan dengan promo atau kupon apapun.</li>
          <li>Reseller juga bisa menjual lagi di marketplace atau di manapun baik secara online maupun offline dengan gambar dan diskripsi bisa diambil di website guepedia.</li>
          <li>Kami memberi saran untuk harga jual sama dengan harga jual yang guepedia berikan kepada customer.</li>
          <li>Pelanggaran ketentuan reseller akan mengakibatkan akun reseller di tutup</li>
          <li>Segala informasi bisa di tanyakan melalui <strong>Whatsapp : 0812 8760 2508</strong> dan <strong>Email : admin@guepedia.com</strong></li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  data: () => ({
    
  }),

  methods: {
  },
};
</script>
<style>
    .term-list{
        max-height: 385px;
        overflow: auto;
    }
</style>