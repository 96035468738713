<template>
  <div id="admin-dashboard" class="mt-3">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">Input Penjualan</h3>
      </div>
    </div>
    <!-- End Page Header -->

    <input-sales/>
  </div>
</template>

<script>
import InputSales from "@/components/Admin/SalesReport/InputSales.vue";
export default {
  components: {
      InputSales
  },
}
</script>
