var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu"},[_c('div',{staticClass:"side-menu card shadow p-3 mb-5"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('hr'),_vm._m(9),_vm._m(10),_c('hr'),_vm._m(11),_vm._m(12),_c('div',{staticClass:"side-menu-item logout my-1 mt-3 text-center"},[_c('button',{staticClass:"btn btn-light logout-btn",on:{"click":_vm.logout}},[_c('i',{staticClass:"bi bi-power"}),_c('span',{staticClass:"ms-3"},[_vm._v("LOGOUT")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center bg-danger text-light side-menu-item"},[_c('a',{staticClass:"text-light",attrs:{"href":"https://reseller.guepedia.com"}},[_c('span',[_vm._v("Bergabung Menjadi Reseller")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item-title my-2"},[_c('span',{staticClass:"fw-bold"},[_vm._v("Menu Penulis")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/dashboard"}},[_c('i',{staticClass:"bi bi-speedometer"}),_c('span',{staticClass:"ms-3"},[_vm._v("Dashboard")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/upload"}},[_c('i',{staticClass:"bi bi-upload"}),_c('span',{staticClass:"ms-3"},[_vm._v("Upload Naskah")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/projects"}},[_c('i',{staticClass:"bi bi-book"}),_c('span',{staticClass:"ms-3"},[_vm._v("Naskah Saya")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/programreview"}},[_c('i',{staticClass:"bi bi-book"}),_c('span',{staticClass:"ms-3"},[_vm._v("Review Buku")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/hak-cipta"}},[_c('i',{staticClass:"bi bi-book"}),_c('span',{staticClass:"ms-3"},[_vm._v("Daftar Hak Cipta")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/sales"}},[_c('i',{staticClass:"bi bi-journal-check"}),_c('span',{staticClass:"ms-3"},[_vm._v("Laporan Penjualan")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/royalty"}},[_c('i',{staticClass:"bi bi-cash-coin"}),_c('span',{staticClass:"ms-3"},[_vm._v("Bagi Hasil")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item-title my-2"},[_c('span',{staticClass:"fw-bold"},[_vm._v("Data Transaksi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/transaction"}},[_c('i',{staticClass:"bi bi-cash"}),_c('span',{staticClass:"ms-3"},[_vm._v("Transaksi Saya")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item-title my-2"},[_c('span',{staticClass:"fw-bold"},[_vm._v("Pengaturan Akun")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-item my-1"},[_c('a',{attrs:{"href":"/account"}},[_c('i',{staticClass:"bi bi-gear"}),_c('span',{staticClass:"ms-3"},[_vm._v("Pengaturan Profil")])])])
}]

export { render, staticRenderFns }